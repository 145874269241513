import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ErrorMessage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <ErrorMessage>
      <h1>404</h1>
    </ErrorMessage>
  </Layout>
);

export default NotFoundPage;
